import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { CdkDragRelease } from '@angular/cdk/drag-drop';

//javascript functions
declare function loadImage(fileurl:any, panelsHeight:any, panelsWidth:any, canvasHeight:any, canvasWidth:any, imageOffsetX:any, imageOffsetY:any, pitch:any, leds:any): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  signForm: UntypedFormGroup;
  signGrid = [];
  readySign = false;
  backButton = false;
  height = 0;
  width = 0;
  comparisonHeight = 6;
  wrapperHeight = 560;
  wrapperWidth = 560;
  imageOffsetX = 0;
  imageOffsetY = 0;
  pitch = "";
  widths = ['1 Panel', '2 Panels', '3 Panels', '4 Panels'];
  pitches = ['4mm', '6mm', '9mm', '12mm'];
  error = false;
  errorMessage = '';
  baseurl;
  file;
  fileurl;
  loading=false;
  loaded=false;
  firstLoad=true;
  sign;
  boundary;
  photo;
  showBasePhoto=false;
  isDragging=false;
  showLEDs=true;

  constructor(public fb: UntypedFormBuilder, private httpClient: HttpClient, private router: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getSettings('settings');
    this.signForm = this.fb.group({
      height: [1, Validators.required],
      width: [1, Validators.required],
      pitch: ['12mm', Validators.required]
    });
  }

  done() {
    this.router.queryParams.subscribe(params => {
      if(params['height']) {
        this.signForm = this.fb.group({
          height: [params['height'], Validators.required],
          width: [params['width'], Validators.required],
          pitch: [params['pitch'], Validators.required]
        });
        this.submit();
      }
    });
  }

  submit() {
    this.loading = true;
    this.loaded=false;
    this.sign = document.getElementById("wrapper");
    this.sign.style.visibility="hidden";
    this.sign.style.border="black 1px solid";
    this.error = false;
    this.errorMessage = '';
    this.height = this.signForm.value.height;
    this.width = this.signForm.value.width;
    this.pitch = this.signForm.value.pitch;
    this.fileurl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(this.file));
    
    // for horizontal allignment
    this.width = this.width*2;

    this.setSize();
    this.readySign = true;
  }

  back() {
    window.location.reload();
  }

  getSettings(file) {
    this.httpClient.get("./assets/" + file + ".json").subscribe(response => {
      this.baseurl = response['baseUrl'];
      this.done();
    });
  }

  onChange(event) {
    this.file = event.target.files[0];
  }

  async setSize() {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    this.photo = document.getElementById("signImage");

    //560 x 560 is the size of the display area and at least one of the dimensions should be that large before scaling down
    this.wrapperHeight = 560;
    this.wrapperWidth = 560;

    //reset the comparison size
    var comparison = document.getElementById("human");
    comparison.style.height = this.wrapperHeight + "px";

    if(this.height > this.comparisonHeight || this.width > this.comparisonHeight) {
      //scale down the comparison silhouette 
      var higherValue = Math.max(this.height, this.width)
      var comparisonHeightScale = this.comparisonHeight / higherValue;
      var comparisonHeightPixels = this.wrapperHeight * comparisonHeightScale;
      comparison.style.height = comparisonHeightPixels + "px";
    }
    else {
      //scale down the sign
      var pixelsPerFoot = this.wrapperHeight / 6;
      this.wrapperHeight = pixelsPerFoot * this.height;
      this.wrapperWidth = pixelsPerFoot * this.width;
    }

    if(this.width > this.height) {
      var widthToHeightRatio = this.height / this.width;
      this.wrapperHeight = widthToHeightRatio * this.wrapperWidth;
    }
    else if(this.height > this.width) {
      var heightToWitdthRatio = this.width / this.height;
      this.wrapperWidth = heightToWitdthRatio * this.wrapperHeight;
    }

    // if not loaded yet
    if(this.photo.clientHeight==0 || this.photo.clientWidth==0 || this.firstLoad) {
      this.firstLoad=false;
      await delay(500);
      this.setSize();
    }
    else {
      // find width and height differences
      var widthDiff = this.wrapperWidth - this.photo.clientWidth;
      var heightDiff = this.wrapperHeight - this.photo.clientHeight;

      // if photo is too big
      if(widthDiff<0 && heightDiff<0) {
        this.photo.style.height = (this.wrapperHeight).toString()+"px";
        this.photo.style.width = "auto";
      }
      // if photo is too small
      else if(widthDiff>0 && heightDiff>0) {
        if(widthDiff<heightDiff) {
          this.photo.style.height = (this.wrapperHeight).toString()+"px";
          this.photo.style.width = "auto";
        }
        else {
          this.photo.style.width = (this.wrapperWidth).toString()+"px";
          this.photo.style.height = "auto";
        }
      }
      // if only width is too small
      else if(widthDiff>0) {
        this.photo.style.width = (this.wrapperWidth).toString()+"px";
        this.photo.style.height = "auto";
      }
      // if only height is too small
      else if(heightDiff>0) {
        this.photo.style.height = (this.wrapperHeight).toString()+"px";
        this.photo.style.width = "auto";
      }
      widthDiff = Math.round(this.wrapperWidth - this.photo.clientWidth) ;
      heightDiff = Math.round(this.wrapperHeight - this.photo.clientHeight);
      
      //if the photo is too small
      if(heightDiff>0||widthDiff>0) {
        this.setSize();
      }
      else {
        this.loading=false;
        this.sign = document.getElementById("wrapper");
        this.sign.style.visibility="visible";
        this.sign.style.height = this.wrapperHeight + "px";
        this.sign.style.width = this.wrapperWidth + "px";

        var canvas = document.getElementById("canvas_f");
        canvas.style.height = this.wrapperHeight + "px";
        canvas.style.width = this.wrapperWidth + "px";

        if((this.pitch=="12mm" && this.height<7 && this.width<8) || (this.pitch=="9mm" && this.height<2 && this.width<4)) {
          loadImage(this.fileurl, this.height, this.width, this.wrapperHeight, this.wrapperWidth, this.imageOffsetX, this.imageOffsetY, this.pitch, true);
          this.showLEDs = true;
        }
        else {
          loadImage(this.fileurl, this.height, this.width, this.wrapperHeight, this.wrapperWidth, this.imageOffsetX, this.imageOffsetY, this.pitch, false);
          this.showLEDs = false;
        }

        this.firstLoad=true;
        this.loaded=true;
      }
    }
  }

  onDragMoved(e: CdkDragMove) {
    this.isDragging = true;

    var fromLeftOfDraggedElement = e.pointerPosition.x - document.getElementById("signImage").getBoundingClientRect().left;
    var fromTopOfDraggedElement = e.pointerPosition.y - document.getElementById("signImage").getBoundingClientRect().top;

    this.imageOffsetX = e.pointerPosition.x - fromLeftOfDraggedElement - document.getElementById("wrapper").getBoundingClientRect().left;
    this.imageOffsetY = e.pointerPosition.y - fromTopOfDraggedElement - document.getElementById("wrapper").getBoundingClientRect().top;
  }

  onDragReleased(e: CdkDragRelease) {
    this.isDragging = false;
    if((this.pitch=="12mm" && this.height<4 && this.width<8) || (this.pitch=="9mm" && this.height<2 && this.width<4)) {
      loadImage(this.fileurl, this.height, this.width, this.wrapperHeight, this.wrapperWidth, this.imageOffsetX, this.imageOffsetY, this.pitch, true);
      this.showLEDs = true;
    }
    else {
      loadImage(this.fileurl, this.height, this.width, this.wrapperHeight, this.wrapperWidth, this.imageOffsetX, this.imageOffsetY, this.pitch, false);
      this.showLEDs = false;
    }
  }
}