<div class="header">
  <div class="title">SMART SIGN STUDIO</div>
  <img class="logo" src="../assets/logo.png"/>
</div>
<div class="pageBody">
  <div class="formbox">
    <form [formGroup]="signForm" class="form">
      <div class="sign">
        <div class="line">sign</div>
        <div class="text">Each panel is 1ft x 2ft</div>
        <div class="formHeight" id="height">
          <label for="height" class="label" id="heightLabel">Height</label>
          <div class="options">
            <div class="heightInput"># of Panels<input type="number" min="1" name="height" formControlName="height"></div>
          </div>
        </div>
        <div class="formWidth" id="width">
          <label for="width" class="label" id="widthLabel">Width</label>
          <div class="options">
            <div class="widthInput"># of Panels<input type="number" min="1" name="width" formControlName="width"></div>
          </div>
        </div>
        <div class="formPitch" id="pitch">
          <label for="pitch" class="label" id="pitchLabel">Pitch</label>
          <div class="optionsPitch">
            <div for="4mm" class="pitchInput">4mm<input type="radio" name="pitch" value="4mm" formControlName="pitch"></div>
            <div for="6mm" class="pitchInput">6mm<input type="radio" name="pitch" value="6mm" formControlName="pitch"></div>
            <div for="9mm" class="pitchInput">9mm<input type="radio" name="pitch" value="9mm" formControlName="pitch"></div>
            <div for="12mm" class="pitchInput">12mm<input type="radio" name="pitch" value="12mm" formControlName="pitch"></div>
          </div>
        </div>
        <div class="formImage" id="image">
          <label for="image" class="label" id="imageLabel">Image</label>
          <div class="optionsImage">
            <input class="form-control" type="file" (change)="onChange($event)">
          </div>
        </div>
      </div>
      <div class="submitDiv">
        <button type="submit" id="submit" class="submit submitLabel" expand="block" (click)="submit()" [disabled]="signForm.invalid">Submit</button>
      </div>
      <div class="resetDiv">
        <button *ngIf="readySign || backButton" (click)="back()" class="reset">Reset</button>
      </div>
      <a href="https://sunshinedm.com/tradeshow/" target="_blank" class="contact">Contact Sales</a>
    </form>
    </div>
    <div class="finishBox">
      <img *ngIf="this.loading" class="spinner" src="assets/spinner.svg" />
      <div class="finishContent" [style.visibility]="(loading || !loaded) ? 'hidden' : 'visible'">
        <div [ngClass]="(showLEDs) ? 'formFinish' : 'formFinishNoLED'" id="wrapper" style="visibility: hidden;" (mouseover)="showBasePhoto = true" (mouseleave)="showBasePhoto = false">
          <img [src]="fileurl" id="signImage" cdkDrag (cdkDragMoved)="onDragMoved($event)" (cdkDragReleased)="onDragReleased($event)" (mouseover)="showBasePhoto = true" (mouseleave)="showBasePhoto = false">       
        </div>
        <canvas id="canvas_f" [ngClass]="(showLEDs) ? 'formFinish' : 'formFinishNoLED'" style="z-index:10000;" [style.visibility]="(showBasePhoto || isDragging || !readySign || loading || !loaded || !showLEDs) ? 'hidden' : 'visible'" (mouseover)="showBasePhoto = true" ></canvas>
        <div *ngIf="readySign" class="dimensionsLabel">{{this.height}}ft x {{this.width}}ft</div>
      </div>
      <div id="humanWrapper" class="humanWrapper" [style.visibility]="(loading || !loaded) ? 'hidden' : 'visible'">
        <img class="human" id="human" src="assets/human.png">
      </div>
      <div class="displayNone">
        <canvas id="canvas" style="image-rendering: pixelated;"></canvas>
        <canvas id="canvas_s" style="image-rendering: pixelated;"></canvas>
        <canvas id="canvas_l" style="image-rendering: pixelated;"></canvas>
      </div>
      <canvas id="canvas_l" style="image-rendering: pixelated;"></canvas>
    </div>
    <div class="copyright">© 2023 Sunshine Electronic Display Corporation. All rights reserved.</div>
  </div>